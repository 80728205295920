import React from "react";
import {Link, graphql} from "gatsby";
import {MDXRenderer} from "gatsby-plugin-mdx";
import Layout from "../components/layout";
import Meta from "../components/meta";

import backIcon from "../images/back.svg";
import Img from "gatsby-image/index";
import RequestDemoMiniForm from "../components/request-demo-mini-form";

const StaticPageTemplate = ({data, pageContext, location}) => {
    if (!data) {
        return false;
    }
    const post = data.mdx;
    const siteTitle = data.site.siteMetadata.title;

    return (
        <Layout location={location} title={siteTitle}>
            <Meta
                title={post.frontmatter.title}
                description={post.excerpt}
            />
            <div className="banner knowledge_banner knowledge_center_banner static_content_banner">
                <div className={'wrapper'}>
                    <div className="banner_content">
                        <div className="wrapper">
                            <div className="left_col">
                                <h2>{post.frontmatter.title}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="knowledge_2">
                <div className="wrapper">
                    <MDXRenderer>{post.body}</MDXRenderer>
                </div>
            </div>
        </Layout>
    )
}

export default StaticPageTemplate;

export const pageQuery = graphql`
    query StaticPageBySlug($slug: String!) {
        site {
            siteMetadata {
                title
            }
        }
        mdx(frontmatter: { slug: { eq: $slug } }) {
            id
            excerpt(pruneLength: 160)
            body
            frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")
            }
        }
    }
`